import * as React from "react";
import "./titleDescription.scss";

const TitleDescription = ({ title, description }) => (
  <div className="titleDescription">
    <div className="titleDescription__header">
      <h2>
        <span style={{ display: "inline-block" }}>
          {title}
          <div style={{ display: "flex", justifyContent: "center" }} >
            <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginRight: "10px" }}></div>
            <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
            <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "25%", marginLeft: "10px" }}> </div>
          </div>
        </span>
      </h2>
    </div>
    <div className="titleDescription__description">
      {description}
    </div>
  </div>
)

export default TitleDescription;
