import * as React from "react";
import Layout from "./../layout";
import "./projectDetails.scss";
import TitleDescription from "../../components/TitleDescription/titleDescription";
import coral_web from '../../Assets/images/ProjectDetails/Coral/coral_home.png';
import coralFullWebView from '../../Assets/images/ProjectDetails/Coral/coral-web-view.png';
import trueImg from '../../Assets/images/ProjectDetails/true.svg';
import Footer from "../Footer/Footer";
import { graphql } from "gatsby";
import { imagePathURL } from "../../utils/JSONData";

var projectDetails = {};

projectDetails["coral"] = {
  type: "Web Development",
  name: "Coral: Relationship Coach",
  sortDesc: "Real couples need help sustaining real intimacy.",
  firstImg: coral_web,
  briefProjeects: [
    {
      title: "Duration",
      descriptiopn: "6 Months"
    },
    {
      title: "Deliverables",
      descriptiopn: "UX, UI, Design Customizer Tool, Website, Multiple Payment Gateway Integration, Shipping API Integration"
    },
    {
      title: "Technologies",
      descriptiopn: "React Js, React Native,REST API Integration for payment and shipping, Gpay, Apple Pay."
    },
  ],
  challengesFaced: [
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
  ],
  keyFeatureSoluations: [
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    }
  ],
  keyFeatureSoluationsImg: coralFullWebView
};

projectDetails["trofi"] = {
  type: "Web Development",
  name: "trofi",
  sortDesc: "Real couples need help sustaining real intimacy.",
  firstImg: coral_web,
  briefProjeects: [
    {
      title: "Duration",
      descriptiopn: "6 Months"
    },
    {
      title: "Deliverables",
      descriptiopn: "UX, UI, Design Customizer Tool, Website, Multiple Payment Gateway Integration, Shipping API Integration"
    },
    {
      title: "Technologies",
      descriptiopn: "React Js, React Native,REST API Integration for payment and shipping, Gpay, Apple Pay."
    },
  ],
  challengesFaced: [
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
  ],
  keyFeatureSoluations: [
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    }
  ],
  keyFeatureSoluationsImg: coralFullWebView
};

projectDetails["inner-room"] = {
  type: "Web Development",
  name: "inner-room",
  sortDesc: "Real couples need help sustaining real intimacy.",
  firstImg: coral_web,
  briefProjeects: [
    {
      title: "Duration",
      descriptiopn: "6 Months"
    },
    {
      title: "Deliverables",
      descriptiopn: "UX, UI, Design Customizer Tool, Website, Multiple Payment Gateway Integration, Shipping API Integration"
    },
    {
      title: "Technologies",
      descriptiopn: "React Js, React Native,REST API Integration for payment and shipping, Gpay, Apple Pay."
    },
  ],
  challengesFaced: [
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
  ],
  keyFeatureSoluations: [
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    }
  ],
  keyFeatureSoluationsImg: coralFullWebView
};
projectDetails["audiobooks"] = {
  type: "Web Development",
  name: "Almost Everything Audiobooks App",
  sortDesc: "Real couples need help sustaining real intimacy.",
  firstImg: coral_web,
  briefProjeects: [
    {
      title: "Duration",
      descriptiopn: "6 Months"
    },
    {
      title: "Deliverables",
      descriptiopn: "UX, UI, Design Customizer Tool, Website, Multiple Payment Gateway Integration, Shipping API Integration"
    },
    {
      title: "Technologies",
      descriptiopn: "React Js, React Native,REST API Integration for payment and shipping, Gpay, Apple Pay."
    },
  ],
  challengesFaced: [
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
    {
      title: "Online Decal Customizer",
      description: "Online decal customizer is the tool that allows the user to create or customize the wall decals as per their choice."
    },
  ],
  keyFeatureSoluations: [
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    },
    {
      description: "Custom decal creator is one of the most amazing features that allows the users to customize the decal as per their choice. Here, the users can add his own graphic or select one from the templates, merge them, change colours, add text, add images, etc. as per their requirements of the house. This provides a complete customize decal that would perfectly suit the ambience of the room."
    }
  ],
  keyFeatureSoluationsImg: coralFullWebView
};

const ProjectDetails = (props) => {
  const productName = props.path.replace('/', '');
  const product = projectDetails[productName];

  const findArrayElementByTitle = (array, title) => {
    return array.find((element) => {
      return element.attributes.headerProjectName.toLowerCase().includes(title)
    })
  }
  const data = findArrayElementByTitle(props.data.allProjectDetailsPages.nodes[0].data, productName);
  if (!data) {
    return (
      <Layout>
        <div className="titleDescription">
          <div
            className="titleDescription__first-section"
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="spinner-grow text-secondary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
  return (
    <Layout>
      <div className="titleDescription">
        <div className="titleDescription__first-section">
          <h3>{data.attributes.headerTitle}</h3>
          <h2>{data.attributes.headerProjectName}</h2>
          <h6>{data.attributes.projectDescription}</h6>
          <img src={imagePathURL + data.attributes.headerImage.data.attributes.url} alt="coral-web" />
        </div>

        <div className="titleDescription__project__brief">
          <div className="row">
            <div className="col-md-6">
              {product.briefProjeects.map((briefProjeect, index) => {
                return (
                  <div key={index}>
                    <div className="title">{briefProjeect.title}</div>
                    <div className="description">{data.attributes[briefProjeect.title.toLowerCase()]}</div>
                    <div><hr className="line" /></div>
                  </div>
                )
              })}
            </div>
            <div className="col-md-6">
              <h2>Project Brief</h2>
              <h5>{data.attributes.projectBrief}</h5>
            </div>
          </div>
        </div>

        <div className="titleDescription__challenges_faced">
          <h2>Challenges We Faced</h2>
          {data.attributes.projectChallenges.data.map((challenge, index) => {
            return (
              <div key={index} className="d-flex">
                <div className="titleDescription__image-section">
                  <img src={trueImg} alt="true-logo" />
                </div>
                <div>
                  <h3>{challenge.attributes.title}</h3>
                  <p>{challenge.attributes.subTitle}</p>
                </div>
              </div>
            )
          })}
        </div>

        <div className="titleDescription__key-feature-soluations">
          <h2>Key Features and Solution </h2>
          <div className="row">
            <div className="col-md-6">
              {
                data.attributes.features_solutions.data.map((keyFeatureSoluation, index) => {
                  return (
                    <div key={index} className="d-flex feature-descripiton">
                      <h1 className="">{index + 1}</h1>
                      <h6>{keyFeatureSoluation.attributes.description}</h6>
                    </div>
                  )
                })
              }
            </div>
            <div className="col-md-6 text-center">
              <img src={imagePathURL + data.attributes.featuresSolutionImage.data.attributes.url} className="web-view-img" alt="keyFeature" />
            </div>
          </div>
        </div>
        <div>
          <TitleDescription title={data.attributes.ImpactTitle} description={data.attributes.ImpactDescription} />
        </div>
        <div className="titleDescription__footer">
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allProjectDetailsPages {
      nodes {
        data {
          attributes {
            headerProjectName
            headerTitle
            ImpactDescription
            ImpactTitle
            deliverables
            duration
            featuresSolution
            featuresSolutionImage {
              data {
                attributes {
                  url
                }
              }
            }
            features_solutions {
              data {
                attributes {
                  description
                }
              }
            }
            headerImage {
              data {
                attributes {
                  url
                }
              }
            }
            projectBrief
            projectChallenges {
              data {
                attributes {
                  title
                  subTitle
                }
              }
            }
            projectDescription
            technologies
          }
        }
      }
    }
  }
`;
export default ProjectDetails;
